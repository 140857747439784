<template>
  <vx-card :title="title">
    <vs-tabs :value="activeTab">
      <vs-tab label="SAF">
        <Saf></Saf>
      </vs-tab>
      <vs-tab label="Waitting">
        <Waiting></Waiting>
      </vs-tab>
      <vs-tab label="Approved">
        <Approved></Approved>
      </vs-tab>
      <vs-tab label="Executed">
        <Executed></Executed>
      </vs-tab>
      <vs-tab label="Canceled">
        <Canceled></Canceled>
      </vs-tab>
      <vs-tab label="Cancel Execute">
        <CanceledExecute></CanceledExecute>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Saf from "./_tab/SafTab.vue"
import Approved from "./_tab/ApprovedTab.vue"
import Canceled from "./_tab/CanceledTab.vue"
import Waiting from "./_tab/WaitingTab.vue"
import CanceledExecute from "./_tab/CanceledExecute.vue"
import Executed from "./_tab/Executed.vue"

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Saf,
    Approved,
    Canceled,
    Waiting,
    CanceledExecute,
    Executed,
  },
  data() {
    return {
      title: "Stock Adjustment Form",
      activeTab : this.$route.query.tab ? this.$route.query.tab : 0
    };
  },
};
</script>
